// import "./ui-connections-status.css";

// import { Button, notification, Space } from "antd";
import { Fragment } from "react";
import { Alert, Space } from "antd";

const RobotConnectionStatusUI = (props) => {
  const robot_id = props.RobotId;
  const rtcConnectionState = props.PeerConnectionStatus;
  const serverConnectionStatus = props.ServerConnectionStatus;
  const robotOnlineStatus = props.RobotOnlineStatus;
  const reconnectHandler = props.ReconnectHandler;
  const showAlert = props.ShowAlert;
  const showStatus = props.ShowStatus;

  const vertexStyle = {
    width: "40px", // Adjust the size of the vertex icons as needed
    height: "40px",
    // Add styles for the vertex icons
  };

  const edgeStyle = {
    borderWidth: "1px", // Adjust the thickness of the edges as needed
    borderStyle: "solid",
  };

  return (
    <Fragment>
      {showAlert && (
        <div
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
            display:
              rtcConnectionState != "connected" || !robotOnlineStatus
                ? "block"
                : "none",
          }}
        >
          <Space
            direction="vertical"
            style={{
              width: "100%",
            }}
          >
            {serverConnectionStatus &&
              serverConnectionStatus != "connected" && (
                <Alert
                  message={
                    "Server is OFFLINE. Please check the connection to server!"
                  }
                  type="warning"
                  showIcon
                  closable
                />
              )}
            {!robotOnlineStatus && (
              <Alert
                message={
                  "robot '" +
                  robot_id +
                  "' is OFFLINE. The robot is not connected to server"
                }
                type="warning"
                showIcon
                closable
              />
            )}
            {rtcConnectionState &&
              (rtcConnectionState == "failed" ||
                rtcConnectionState == "disconnected") && (
                <Alert
                  message={
                    "Peer connection to the robot is '" +
                    rtcConnectionState +
                    "'. " +
                    (robotOnlineStatus
                      ? "Reconnecting ...!"
                      : "Auto-reconnect when robot is ONLINE")
                  }
                  type="error"
                  showIcon
                  closable
                />
              )}
            {rtcConnectionState &&
              robotOnlineStatus &&
              rtcConnectionState != "failed" &&
              rtcConnectionState != "disconnected" &&
              rtcConnectionState != "connected" && (
                <Alert
                  message={
                    "(Re-)connecting to the robot. Connection state: " +
                    rtcConnectionState
                  }
                  type="error"
                  // action={
                  //   <Space>
                  //     <Button size="small" type="ghost">
                  //       Done
                  //     </Button>
                  //   </Space>
                  // }
                  showIcon
                  closable
                />
              )}
          </Space>
        </div>
      )}
      {showStatus && (
        <h4>
          Connections to: {"Server (" + serverConnectionStatus + ")"} |{" "}
          {/* {"Robot-Server: (" +
          (robotOnlineStatus ? "connected" : "disconnected") +
          ")"} |  */}
          {"Robot (" + rtcConnectionState + ")"}
        </h4>
      )}
      {/* <div id="container">
        <div id="robot"></div>
        <div id="user"></div>
        <div id="server"></div>
        <div id="connector"></div>
      </div> */}
    </Fragment>
  );
};

RobotConnectionStatusUI.defaultProps = {
  ShowAlert: true,
  ShowStatus: true,
};

export default RobotConnectionStatusUI;
