import { Fragment, useEffect, useState } from "react";
import {
  createCommand_BackwardLeft,
  createCommand_BackwardRight,
  createCommand_ForwardLeft,
  createCommand_ForwardRight,
  createCommand_Sit,
  createCommand_Stand,
  createCommand_Stop,
  createCommand_StrafeLeft,
  createCommand_StrafeRight,
  createCommand_TurnLeft,
  createCommand_TurnRight,
  createCommand_WalkBackward,
  createCommand_WalkForward,
} from "../spot-command-builder";
import "./spot-move-control.css";

const SpotMoveCommander = (props) => {
  const onCommandCreated = props.OnCommandCreated;
  const enableKeyboard = props.EnableKeyboard;

  const [keysPressed, setKeysPressed] = useState(new Set());
  const controlKeys = new Set([
    "ArrowUp",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "w",
    "s",
    "a",
    "d",
    "q",
    "e",
    " ",
    "Control",
  ]);

  const handleCommandButtonClicked = (cmd) => {
    if (onCommandCreated) onCommandCreated(cmd);
  };

  useEffect(() => {
    console.log("Keys pressed: ", keysPressed);
    if (!onCommandCreated) return;

    const ctrlKey = keysPressed.has("Control");
    // for (const key of keysPressed) {
    //   const cmd = getCommandByKey(key, ctrlKey);
    //   console.log("key: ", key);
    //   if (cmd != null) {
    //     onCommandCreated(cmd);
    //   }
    // }

    const cmd = getKeyspressedCommand(ctrlKey);
    if (cmd != null) onCommandCreated(cmd);
  }, [keysPressed]);

  const handleKeyDown = (event) => {
    if (!controlKeys.has(event.key)) return;
    setKeysPressed((prevKeys) => new Set([...prevKeys, event.key]));
    event.preventDefault();
  };

  const handleKeyUp = (event) => {
    if (!controlKeys.has(event.key)) return;
    setKeysPressed((prevKeys) => {
      const updatedKeys = new Set(prevKeys);
      updatedKeys.delete(event.key);
      return updatedKeys;
    });
    event.preventDefault();
  };

  const getKeyspressedCommand = () => {
    if (keysPressed.has(" ")) {
      return createCommand_Stop();
    }

    if (["w", "ArrowUp"].some((item) => keysPressed.has(item))) {
      if (keysPressed.has("Control")) {
        return createCommand_Stand();
      }
      if (["a", "ArrowLeft"].some((item) => keysPressed.has(item))) {
        return createCommand_ForwardLeft();
      }
      if (["d", "ArrowRight"].some((item) => keysPressed.has(item))) {
        return createCommand_ForwardRight();
      }

      return createCommand_WalkForward();
    }

    if (["s", "ArrowDown"].some((item) => keysPressed.has(item))) {
      if (keysPressed.has("Control")) {
        return createCommand_Sit();
      }
      if (["a", "ArrowLeft"].some((item) => keysPressed.has(item))) {
        return createCommand_BackwardLeft();
      }
      if (["d", "ArrowRight"].some((item) => keysPressed.has(item))) {
        return createCommand_BackwardRight();
      }
      return createCommand_WalkBackward();
    }

    if (["a", "ArrowLeft"].some((item) => keysPressed.has(item))) {
      if (keysPressed.has("Control")) {
        return createCommand_TurnLeft();
      }
      return createCommand_StrafeLeft();
    }
    if (["d", "ArrowRight"].some((item) => keysPressed.has(item))) {
      if (keysPressed.has("Control")) {
        return createCommand_TurnRight();
      }
      return createCommand_StrafeRight();
    }

    if (keysPressed.has("q")) {
      return createCommand_TurnLeft();
    }

    if (keysPressed.has("e")) {
      return createCommand_TurnRight();
    }

    return null;
  };

  const getCommandByKey = (key, ctrlKey = false) => {
    switch (key) {
      case "e":
      case "ArrowUp":
        if (ctrlKey) return createCommand_Stand();
        else return createCommand_WalkForward();
      case "d":
      case "ArrowDown":
        if (ctrlKey) return createCommand_Sit();
        else return createCommand_WalkBackward();

      case "s":
      case "ArrowLeft":
        if (ctrlKey) return createCommand_TurnLeft();
        else return createCommand_StrafeLeft();

      case "f":
      case "ArrowRight":
        if (ctrlKey) return createCommand_TurnRight();
        else return createCommand_StrafeRight();
      case " ":
        return createCommand_Stop();
      default:
        break;
    }
    return null;
  };

  const handleKeyDown1 = (event) => {
    console.log("Event key: ", event.key);
    switch (event.key) {
      case "e":
      case "ArrowUp":
        if (event.ctrlKey) handleCommandButtonClicked(createCommand_Stand());
        else handleCommandButtonClicked(createCommand_WalkForward());
        event.preventDefault();
        break;
      case "d":
      case "ArrowDown":
        if (event.ctrlKey) handleCommandButtonClicked(createCommand_Sit());
        else handleCommandButtonClicked(createCommand_WalkBackward());
        event.preventDefault();
        break;
      case "s":
      case "ArrowLeft":
        if (event.ctrlKey) handleCommandButtonClicked(createCommand_TurnLeft());
        else handleCommandButtonClicked(createCommand_StrafeLeft());
        event.preventDefault();
        break;
      case "f":
      case "ArrowRight":
        if (event.ctrlKey)
          handleCommandButtonClicked(createCommand_TurnRight());
        else handleCommandButtonClicked(createCommand_StrafeRight());
        event.preventDefault();
        break;
      case " ":
        handleCommandButtonClicked(createCommand_Stop());
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <div
        className="spot-control"
        tabIndex={0}
        onKeyDown={enableKeyboard ? handleKeyDown : (evt) => {}}
        onKeyUp={enableKeyboard ? handleKeyUp : (evt) => {}}
      >
        <div className="spot-control-buttons" tabIndex={0}>
          <button
            className="forward"
            onClick={() =>
              handleCommandButtonClicked(createCommand_WalkForward())
            }
          >
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2 L2 15 L22 15 Z" />
            </svg>
          </button>
          <button
            className="sleft"
            onClick={() =>
              handleCommandButtonClicked(createCommand_StrafeLeft())
            }
          >
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 12 L15 2 L15 22 Z" />
            </svg>
          </button>

          <button
            className="sright"
            onClick={() =>
              handleCommandButtonClicked(createCommand_StrafeRight())
            }
          >
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 12 L9 2 L9 22 Z" />
            </svg>
          </button>
          <button
            className="turnleft"
            onClick={() => handleCommandButtonClicked(createCommand_TurnLeft())}
          >
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 12H6M12 5l-7 7 7 7" />
            </svg>
          </button>
          <button
            className="stop"
            onClick={() => handleCommandButtonClicked(createCommand_Stop())}
          >
            STOP
            {/* <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <rect x="6" y="6" width="12" height="12" />
          </svg> */}
          </button>
          <button
            className="turnright"
            onClick={() =>
              handleCommandButtonClicked(createCommand_TurnRight())
            }
          >
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 12h13M12 19l7-7-7-7" />
            </svg>
          </button>
          <button
            className="backward"
            onClick={() =>
              handleCommandButtonClicked(createCommand_WalkBackward())
            }
          >
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22 L2 9 L22 9 Z" />
            </svg>
          </button>

          <button
            className="sit btn-primary"
            onClick={() => handleCommandButtonClicked(createCommand_Sit())}
          >
            Sit
          </button>
          <button
            className="stand btn-primary"
            onClick={() => handleCommandButtonClicked(createCommand_Stand())}
          >
            Stand
          </button>

          {/* -------------- */}
        </div>
      </div>
    </Fragment>
  );
};

export default SpotMoveCommander;
