import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import VideoTrackViewerNew from "../components/video-viewer/VideoTrackViewerNew";
import { message } from "antd";
import { syntheticVideoStream } from "../utils/graph";
import RobotConnectionStatusUI from "../components/common/ui-connections-status";
import { useServerConnection } from "../contexts/ServerConnectionContext";
import { useRobotPeerConnection } from "../contexts/RobotConnectionContext";

const PageRobotCamera = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const qsShowDelayGraph =
    searchParams.has("show-delay") &&
    searchParams.get("show-delay").trim().toLocaleLowerCase() == "true";

  const qsShowStatus = searchParams.has("show-status")
    ? searchParams.get("show-status").trim().toLocaleLowerCase() == "true"
    : false;

  console.log("qsShowStatus: ", qsShowStatus);

  const qsCamWidth = searchParams.has("cam-width")
    ? searchParams.get("cam-width")
    : "100%";
  const qsCamHeight = searchParams.has("cam-height")
    ? searchParams.get("cam-height")
    : "95vh";
  console.log("camwidth: ", qsCamWidth);

  const [messageApi, contextHolder] = message.useMessage();

  const { id } = useParams();

  // const [signal_ready, signal_message, signal_send, signal_Error] =
  //   useContext(WebsocketContext);

  const {
    isOpen: signal_ready,
    isConnecting,
    message: signal_message,
    sendMessage: signal_send,
    subscribe,
    error: signal_Error,
    reconnectCountDown,
  } = useServerConnection();

  const localStreamRef = useRef(null);
  const getLocalVideoStream = () => {
    if (!localStreamRef.current) {
      localStreamRef.current = syntheticVideoStream();
    }
    return localStreamRef.current;
  };

  // let localStream = getLocalVideoStream();

  // const webRtcConfig = {
  //   robotId: id,
  //   dataChannels: ["chat"],
  //   signal_ready: signal_ready,
  //   signal_message: signal_message,
  //   signal_send: signal_send,
  //   LocalStream: getLocalVideoStream,
  // };

  // const [
  //   rtcConnectionState,
  //   mediaTracks,
  //   channelMessage,
  //   channelSend,
  //   error,
  //   robotOnlineStatus,
  // ] = useWebRTC(webRtcConfig);

  const {
    setRobotId,
    connectionState: rtcConnectionState,
    mediaTracks,
    channelMessage,
    channelSend,
    connect,
    // close: closePeerConnection,
    error,
    robotOnlineStatus,
    setStatsEnabled,
  } = useRobotPeerConnection();

  const [robotStatus, setRobotStatus] = useState(null);
  const [robotErrorInfo, setRobotErrorInfo] = useState(null);
  const [missionStatus, setMissionStatus] = useState(null);

  useEffect(() => {
    setRobotId(id);
  }, [id]);

  useEffect(() => {
    setStatsEnabled(qsShowDelayGraph);
  }, [qsShowDelayGraph]);

  useEffect(() => {
    if (!channelMessage) return;
    // console.log("received message from robot: ", channelMessage);
    if (channelMessage.channel == "chat") {
      const data = JSON.parse(channelMessage.data);
      const msg_type = data["msg_type"];

      if (msg_type == "robot_state") {
        const msg_body = data["msg_body"];
        let robotInfo = msg_body["common_info"];
        const errorInfo = msg_body["error_info"];
        const missionInfo = msg_body["mission_info"];
        // robotInfo["icon"] = "/images/gocart.jpg";
        robotInfo["error_info"] = errorInfo;
        // console.log("robotInfo: ", robotInfo);
        // TODO: check map

        setRobotErrorInfo(errorInfo);
        setRobotStatus(robotInfo);
        setMissionStatus(missionInfo);
      }
    }
  }, [channelMessage]);

  // const onCameraOptionChanged = (cameraOption) => {
  //   console.log("camera changed to", cameraOption);
  // };

  const onJitterGraphInitCompleted = () => {
    if (localStreamRef.current) {
      console.log("Stop localStream");
      localStreamRef.current.getTracks().forEach(function (track) {
        track.stop();
      });
      localStreamRef.current = null;
    }
  };
  const divStyle = { width: qsCamWidth, height: qsCamHeight };
  console.log("divStyle: ", divStyle);

  const mainDivStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "100vh",
    width: "100%",
    margin: "0",
  };
  return (
    <div style={mainDivStyle}>
      <div>
        <RobotConnectionStatusUI
          RobotId={id}
          ServerConnectionStatus={signal_ready ? "connected" : "disconnected"}
          PeerConnectionStatus={rtcConnectionState}
          RobotOnlineStatus={robotOnlineStatus}
          ShowAlert={true}
          ShowStatus={qsShowStatus}
        ></RobotConnectionStatusUI>

        <div
        // style={divStyle}
        >
          {contextHolder}
          <VideoTrackViewerNew
            CameraStyle={{ height: qsCamHeight, width: qsCamWidth }}
            mediaTracks={mediaTracks}
            ShowDelayTime={qsShowDelayGraph}
          ></VideoTrackViewerNew>
        </div>
      </div>
    </div>
  );
};

export default PageRobotCamera;
