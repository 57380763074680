import { Button, Checkbox, Collapse, Space } from "antd";
import React from "react";
import SpotMoveCommander from "./spot-move-control";
import SpotCommandButtons from "./spot-command-buttons";
import SpotSortieCommandBuilderV3 from "./spot-sortie-command-ui-v3";
import { MissionsBuildProvider } from "../context/mission-builder-context";
import SpotJoystickCommand from "./spot-joystick-command";

import { MessageOutlined } from "@ant-design/icons";
import { createCommand_PlayAudio } from "../spot-command-builder";

const { Panel } = Collapse;

const SpotControlPanel = (props) => {
  const onCommandCreated = props.OnCommandCreated;
  const onMissionDescriptionCreatedOK = props.OnMissionDescriptionCreatedOK;
  const onSortieDescriptionCreatedOK = props.OnSortieDescriptionCreatedOK;
  const isJoystickEnabled = props.JoystickEnabled;

  const handleMoveCommand = (cmd) => {
    if (onCommandCreated) onCommandCreated(cmd, true);
  };

  const handleJoystickCommand = (cmd) => {
    if (onCommandCreated) onCommandCreated(cmd, true);
  };

  const handleControlCommand = (cmd) => {
    if (onCommandCreated) onCommandCreated(cmd);
  };

  const handleMissionCommand = (cmd) => {
    if (onCommandCreated) onCommandCreated(cmd);
  };

  const handlerMissionDescriptionCreatedOK = (missionDescription) => {
    if (onMissionDescriptionCreatedOK)
      onMissionDescriptionCreatedOK(missionDescription);
  };

  const handleOnSortieDescriptionCreatedOK = (missionDescriptions) => {
    if (onSortieDescriptionCreatedOK)
      onSortieDescriptionCreatedOK(missionDescriptions);
  };

  const handlePlayAudioCommandButtonClicked = (cmd) => {
    if (onCommandCreated) onCommandCreated(cmd, true);
  };

  return (
    <>
      <Collapse defaultActiveKey={[0, "voice_msg"]}>
        <Panel header="Move control" key="0">
          <SpotMoveCommander
            EnableKeyboard={Collapse}
            //   visible={isRobotControlEnabled}
            //   onMoveCommand={handleMoveCommand}
            OnCommandCreated={handleMoveCommand}
          ></SpotMoveCommander>
        </Panel>

        <Panel header="Control commands" key="1">
          <SpotCommandButtons
            OnCommandCreated={handleControlCommand}
          ></SpotCommandButtons>
        </Panel>

        {isJoystickEnabled && (
          <Panel header="Joystick" key="12">
            <SpotJoystickCommand
              OnCommandCreated={handleJoystickCommand}
            ></SpotJoystickCommand>
          </Panel>
        )}
        <Panel header="Voice messages" key="voice_msg">
          <div>
            <Space wrap size={[8, 12]}>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("greeting.mp3")
                  )
                }
              >
                안녕하세요
              </Button>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("train_arriving.mp3")
                  )
                }
              >
                곧 월미바다 열차가 도착합니다
              </Button>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("follow_me.mp3")
                  )
                }
              >
                저를 따라오세요
              </Button>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("boarding_side_left.mp3")
                  )
                }
              >
                타는곳은 왼쪽 입니다
              </Button>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("boarding_side_right.mp3")
                  )
                }
              >
                타는곳은 오른쪽 입니다
              </Button>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("make_my_way.mp3")
                  )
                }
              >
                양보해주세요
              </Button>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("goodbye.mp3")
                  )
                }
              >
                안녕히 가세요
              </Button>
              <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("thankyou.mp3")
                  )
                }
              >
                감사합니다
              </Button>
              {/* <Button
                block
                icon={
                  <MessageOutlined
                    style={{ fontSize: "16px", color: "#08c" }}
                  />
                }
                onClick={() =>
                  handlePlayAudioCommandButtonClicked(
                    createCommand_PlayAudio("follow_you.mp3")
                  )
                }
              >
                당신을 따르겠습니다
              </Button> */}
            </Space>
          </div>
        </Panel>
        {/* <Panel header="Mission" key="2">
          <SpotMissionCommandBuilder
            OnCommandCreated={handleMissionCommand}
          ></SpotMissionCommandBuilder>
        </Panel> */}

        {/* <Panel header="Mission-old" key="3">
          <SpotMissionCommandBuilder
            OnMissionCancelCommandCreated={handleMissionCommand}
            OnMissionFinishCommandCreated={handleMissionCommand}
            OnMissionDescriptionCreatedOK={handlerMissionDescriptionCreatedOK}
          ></SpotMissionCommandBuilder>
        </Panel> */}
        {/* <Panel header="Missions" key="4">
          <SpotSortieCommandBuilder
            OnMissionCancelCommandCreated={handleMissionCommand}
            OnMissionFinishCommandCreated={handleMissionCommand}
            OnMissionDescriptionCreatedOK={handleOnSortieDescriptionCreatedOK}
          ></SpotSortieCommandBuilder>
        </Panel> */}
        {/* <Panel header="Missions new" key="5">
          <SpotSortieCommandBuilderV2
            OnMissionCancelCommandCreated={handleMissionCommand}
            OnMissionFinishCommandCreated={handleMissionCommand}
            OnMissionDescriptionCreatedOK={handleOnSortieDescriptionCreatedOK}
          ></SpotSortieCommandBuilderV2>
        </Panel> */}
        <Panel header="Missions new" key="6">
          <MissionsBuildProvider>
            <SpotSortieCommandBuilderV3
              OnMissionCancelCommandCreated={handleMissionCommand}
              OnMissionFinishCommandCreated={handleMissionCommand}
              OnMissionDescriptionCreatedOK={handleOnSortieDescriptionCreatedOK}
            ></SpotSortieCommandBuilderV3>
          </MissionsBuildProvider>
        </Panel>
      </Collapse>
    </>
  );
};
export default SpotControlPanel;
