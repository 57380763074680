import { Button } from "antd";
import {
  createCommand_Connect,
  createCommand_EStop,
  createCommand_MissionCancel,
  createCommand_MissionPause,
  createCommand_MissionRestart,
  createCommand_MissionStart,
  createCommand_PowerOn,
  createCommand_Walk,
} from "../spot-command-builder";

import "./spot-command-buttons.css";

const SpotCommandButtons = (props) => {
  const onCommandCreated = props.OnCommandCreated;

  const handleCommandButtonClicked = (cmd) => {
    if (props.OnCommandCreated) props.OnCommandCreated(cmd);
  };

  return (
    <div className="spot-buttons-grid">
      <button
        className="connect btn-primary"
        onClick={() => handleCommandButtonClicked(createCommand_Connect())}
      >
        Connect
      </button>

      <button
        className="power-on btn-primary"
        onClick={() => handleCommandButtonClicked(createCommand_PowerOn())}
      >
        Power On
      </button>

      <button
        className="e-stop btn-primary"
        onClick={() => handleCommandButtonClicked(createCommand_EStop())}
      >
        E-Stop
      </button>

      <button
        className="walk btn-primary"
        onClick={() => handleCommandButtonClicked(createCommand_Walk())}
      >
        Walk
      </button>

      <button
        className="start btn-primary"
        onClick={() => handleCommandButtonClicked(createCommand_MissionStart())}
      >
        Start
      </button>

      <button
        className="pause btn-primary"
        onClick={() => handleCommandButtonClicked(createCommand_MissionPause())}
      >
        Pause
      </button>

      <button
        className="restart btn-primary"
        onClick={() =>
          handleCommandButtonClicked(createCommand_MissionRestart())
        }
      >
        Restart
      </button>

      <button
        className="cancel-mission btn-primary"
        onClick={() =>
          handleCommandButtonClicked(createCommand_MissionCancel())
        }
      >
        Cancel Mission
      </button>
    </div>
  );
};
export default SpotCommandButtons;
