import "./robot-connection-status.css";
import { Alert, Button, Progress, Space, Tooltip, FloatButton } from "antd";
import { Fragment, useEffect } from "react";
import {
  LoadingOutlined,
  ApiOutlined,
  LinkOutlined,
  DisconnectOutlined,
  AudioOutlined,
  AudioMutedOutlined,
  SoundOutlined,
  CameraOutlined,
  VideoCameraOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { useServerConnection } from "../../contexts/ServerConnectionContext";
import { useRobotPeerConnection } from "../../contexts/RobotConnectionContext";

const RobotConnectionStatus = (props) => {
  // const robot_id = props.RobotId;
  // const rtcConnectionState = props.PeerConnectionStatus;
  // const serverConnectionStatus = props.ServerConnectionStatus;
  // const robotOnlineStatus = props.RobotOnlineStatus;
  const reconnectHandler = props.ReconnectHandler;
  const showAlert = props.ShowAlert;
  const showStatus = props.ShowStatus;

  const {
    isOpen: isServerConnectionOpen,
    isConnecting,
    message: signal_message,
    sendMessage: signal_send,
    subscribe,
    error: signal_Error,
    reconnectCountDown,
  } = useServerConnection();

  const {
    robotId: robot_id,
    connectionState: rtcConnectionState,
    mediaTracks,
    channelMessage,
    channelSend,
    connect,
    error,
    robotOnlineStatus,
    enableStats,
    disableStats,
    isReceiveVideo,
    isReceiveAudio,
    isSendingAudio,
    setIsReceiveVideo,
    setIsReceiveAudio,
    setIsSendingAudio,
    mediaError,
    mediaAllowed,
    allowLocalAudio,
    allowLocalVideo,
    allowRemoteAudio,
    allowRemoteVideo,
  } = useRobotPeerConnection();

  const btnEnableReceivingSoundClicked = () => {
    setIsReceiveAudio(!isReceiveAudio);
  };

  const btnEnableReceivingVideoClicked = () => {
    setIsReceiveVideo(!isReceiveVideo);
  };

  const btnEnableSendingAudioClicked = () => {
    setIsSendingAudio(!isSendingAudio);
  };

  useEffect(() => {
    console.log("mediaError: ", mediaError);
  }, [mediaError]);

  const handleReconnectRobot = () => {
    const conf = {};
    connect(conf);
  };
  const handleUpdateRobotConnection = () => {};
  return (
    <Fragment>
      {showAlert && (
        <div
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
            display:
              rtcConnectionState != "connected" || !robotOnlineStatus
                ? "block"
                : "none",
          }}
        >
          <Space
            direction="vertical"
            style={{
              width: "100%",
            }}
          >
            {!isServerConnectionOpen && (
              <Alert
                message={
                  "Server is OFFLINE. Please check the connection to server!"
                }
                type="warning"
                showIcon
                closable
              />
            )}
            {!robotOnlineStatus && (
              <Alert
                message={
                  "robot '" +
                  robot_id +
                  "' is OFFLINE. The robot is not connected to server"
                }
                type="warning"
                showIcon
                closable
              />
            )}
            {rtcConnectionState &&
              (rtcConnectionState == "failed" ||
                rtcConnectionState == "disconnected") && (
                <Alert
                  message={
                    "Peer connection to the robot is '" +
                    rtcConnectionState +
                    "'. " +
                    (robotOnlineStatus
                      ? "Reconnecting ...!"
                      : "Auto-reconnect when robot is ONLINE")
                  }
                  type="error"
                  showIcon
                  closable
                />
              )}
            {rtcConnectionState &&
              robotOnlineStatus &&
              rtcConnectionState != "failed" &&
              rtcConnectionState != "disconnected" &&
              rtcConnectionState != "connected" && (
                <Alert
                  message={
                    "(Re-)connecting to the robot. Connection state: " +
                    rtcConnectionState
                  }
                  type="error"
                  // action={
                  //   <Space>
                  //     <Button size="small" type="ghost">
                  //       Done
                  //     </Button>
                  //   </Space>
                  // }
                  showIcon
                  closable
                />
              )}
          </Space>
        </div>
      )}
      {showStatus && (
        <div className="connections-status-container">
          <div className="server ">
            <Space>
              Server:{" "}
              {isServerConnectionOpen ? (
                <Tooltip title="Server is on">
                  <ApiOutlined className="connected" />
                </Tooltip>
              ) : (
                <Tooltip title="Server is off">
                  <DisconnectOutlined className="disconnected" />
                </Tooltip>
              )}
            </Space>
          </div>
          <div>
            <Space>
              Robot:
              {robotOnlineStatus ? (
                <Tooltip title="Robot Online">
                  <ApiOutlined className="connected" />
                </Tooltip>
              ) : (
                <Tooltip title="Robot Offline">
                  <DisconnectOutlined className="disconnected" />
                </Tooltip>
              )}
              {/* {robotOnlineStatus ? "Online" : "Offline"} */}
            </Space>

            <FloatButton.Group
              trigger="click"
              type="primary"
              style={{
                right: 30,
                top: 25,
              }}
              icon={<ApiOutlined />}
            >
              <FloatButton
                icon={<ApiOutlined />}
                onClick={handleReconnectRobot}
                description="Reconnect"
              />
              <FloatButton
                icon={<DisconnectOutlined />}
                onClick={handleUpdateRobotConnection}
                description="Update connect"
              />
            </FloatButton.Group>
          </div>
          <div>
            <Space>
              Connection:{" "}
              {rtcConnectionState == "connected" ? (
                <Tooltip title="Connected to robot">
                  <LinkOutlined className="connected" />
                </Tooltip>
              ) : (
                <Tooltip title="Connecting to robot...">
                  <LoadingOutlined className="disconnected" />
                </Tooltip>
              )}{" "}
            </Space>
          </div>
          <div>
            {allowLocalAudio && (
              <Tooltip title={mediaError === null ? "" : mediaError}>
                <Button
                  disabled={!mediaAllowed}
                  icon={
                    isSendingAudio ? <AudioOutlined /> : <AudioMutedOutlined />
                  }
                  type={isSendingAudio ? "primary" : "default"}
                  onClick={btnEnableSendingAudioClicked}
                ></Button>
              </Tooltip>
            )}
            {allowRemoteAudio && (
              <Button
                icon={<SoundOutlined />}
                type={isReceiveAudio ? "primary" : "default"}
                onClick={btnEnableReceivingSoundClicked}
              ></Button>
            )}
            {allowRemoteVideo && (
              <Button
                // icon={<CameraOutlined />}
                icon={
                  isReceiveVideo ? <EyeOutlined /> : <EyeInvisibleOutlined />
                }
                type={isReceiveVideo ? "primary" : "default"}
                onClick={btnEnableReceivingVideoClicked}
              ></Button>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default RobotConnectionStatus;
